<div class="report-container">
    <div class="date-selector">
        <div class="date-buttons">
            <button mat-button (click)="yesterdayClickHandler()">Yesterday</button>
            <button mat-button (click)="todayClickHandler()">Today</button>
            <button mat-button (click)="tomorrowClickHandler()">Tomorrow</button>
        </div>
        <div class="date-pickers">
            <dx-date-box
                [value]="selectedStartDate"
                (onValueChanged)="dateChangedStart($event)"
                type="date"
                displayFormat="MM/dd/yyyy"
                placeholder="Start Date">
            </dx-date-box>
        </div>
    </div>

    <div class="grids-container">
        <!-- Summary Grid -->
        <div class="left-panel">
            <div class="report-date">Report date: {{(formattedDate$ | async)}}</div>
            <div class="grid-section">
                <div class="report-header">
                    <div *ngIf="reportTotals$ | async" class="totals-title">{{(reportTotals$ | async)?.totalStockMissing}} items for {{(reportTotals$ | async)?.clientsTotal}} clients are missing as of:<br> {{ generatedAt$ | async | date:'medium' }}</div>
                    <div *ngIf="!(reportTotals$ | async)" class="totals-title">Report not yet created</div>
                    <div class="report-actions">
                        <button mat-raised-button 
                                (click)="regenerateReport()" 
                                [disabled]="(generating$ | async) || dataIsChanging">
                            <mat-icon>refresh</mat-icon>
                            {{ (generating$ | async) ? 'Refreshing...' : 'Refresh' }}
                        </button>
                    </div>
                </div>
                <h2>Stock Summary by Client</h2>
                <dx-data-grid
                    [dataSource]="summaryDataSource"
                    [showBorders]="true"
                    [columnAutoWidth]="true"
                    [wordWrapEnabled]="true"
                    [paging]="false"
                    (onRowClick)="onSummaryRowClick($event)"
                    [selectedRowKeys]="[selectedClient$ | async]">
                    
                    <dxi-column dataField="clientName" caption="Client Name"></dxi-column>
                    <dxi-column dataField="totalRequested" caption="Total Items Requested" dataType="number"></dxi-column>
                    <dxi-column dataField="totalMissing" caption="Total Items Missing" dataType="number"></dxi-column>
                    
                    <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <dxo-selection mode="single" [showCheckBoxesMode]="'none'"></dxo-selection>

                    <div *dxTemplate="let data of 'tooltipTemplate'">
                        {{data.value}}
                    </div>
                </dx-data-grid>
            </div>

            <!-- Detail Grid -->
            <div class="grid-section">
                <h2>Stock Details {{ (selectedClient$ | async) ? 'for ' + (selectedClient$ | async) : '' }}</h2>
                <dx-data-grid
                    [dataSource]="detailDataSource"
                    [showBorders]="true"
                    [columnAutoWidth]="false"
                    [width]="'100%'"
                    [wordWrapEnabled]="true"
                    [paging]="false"
                    (onRowClick)="onDetailRowClick($event)"
                    [selectedRowKeys]="selectedDetailRow ? [selectedDetailRow.sku] : []">
                    
                    <dxi-column dataField="clientName" caption="Client Name" [width]="150"></dxi-column>
                    <dxi-column 
                        dataField="sku" 
                        caption="SKU" 
                        [width]="100"
                        cellTemplate="skuTooltipTemplate"
                        >
                    </dxi-column>
                    <dxi-column dataField="requestedAmount" caption="Requested" [width]="100"></dxi-column>
                    <dxi-column dataField="availableAmount" caption="Available" [width]="100"></dxi-column>
                    <dxi-column dataField="missing" caption="Missing" [width]="100"></dxi-column>
                    
                    <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <dxo-selection mode="single" [showCheckBoxesMode]="'none'"></dxo-selection>

                    <div *dxTemplate="let cell of 'skuTooltipTemplate'">
                        <div [title]="cell.data.description">{{cell.value}}</div>
                    </div>
                </dx-data-grid>
            </div>
        </div>
        <div class="right-panel">
            <!-- Detail View -->
            <div class="detail-section" *ngIf="selectedDetailRow">
                <h3>Details for SKU: {{selectedDetailRow.sku}}</h3>
                
                <!-- Orders -->
                <div class="detail-subsection">
                    <h4>Orders</h4>
                    <dx-data-grid
                        [dataSource]="selectedDetailRow.orders"
                        [showBorders]="true"
                        [paging]="false"
                        [columnAutoWidth]="true">
                        
                        <dxi-column dataField="orderId" caption="Order ID"></dxi-column>
                        <dxi-column dataField="orderNumber" caption="Order Number"></dxi-column>
                        <dxi-column dataField="customer" caption="Customer" [calculateCellValue]="getCustomerText"></dxi-column>
                    </dx-data-grid>
                </div>

                <!-- Items in Stock -->
                <div class="detail-subsection">
                    <h4>Items in Stock</h4>
                    <dx-data-grid
                        [dataSource]="selectedDetailRow.itemsInStock"
                        [showBorders]="true"
                        [paging]="false"
                        [columnAutoWidth]="true">
                        
                        <dxi-column dataField="itemId" caption="Item ID"></dxi-column>
                        <dxi-column dataField="serialNumer" caption="Serial Number"></dxi-column>
                        <dxi-column dataField="statusId" caption="Status" [calculateCellValue]="getStatusText">

                        </dxi-column>
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
</div> 